import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import ReactShadowScroll from 'react-shadow-scroll';
import moment from 'moment';
import { IoIosArrowDown, IoMdDownload } from 'react-icons/io';
import { BsQuestionCircle } from 'react-icons/bs';
import * as XLSX from 'xlsx';
import { getUserInfo } from '../../store/actions';
import { respondTo } from '../../theme/mixin';
import { numberWithDots } from '../../utils/utils';
import { Box, Button, Col, Flex, Text, Wrapper } from '../../components/Ui';

const CustomBox = styled(Box)`
  & > * + * {
    border-top: 1px solid ${({ theme }) => theme.colore_bordo_box};
  }
`;
const CustomCol = styled(Col)`
  svg {
    color: ${({ theme }) => theme.primary};
  }
`;
const CustomFlexHeader = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colore_bordo_box};
  display: none;
  ${respondTo.sm`
  display: flex;
     `};
`;
const CustomColPoint = styled(Col)`
  width: 25%;
  ${respondTo.sm`
  width: 20%;
     `};
`;
const CustomText = styled(Text)`
  display: block;
  ${respondTo.sm`
  display: none;
     `};
`;
const Details = styled.details`
  padding: 15px;

  summary {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    position: relative;
    font-weight: bold;

    margin-bottom: 5px;
  }
`;
const DownloadButton = styled(Button)`
  margin-top: 20px;
  background-color: #252525;
  color: #fff;
  border: none;

  svg {
    color: #a09f9f;
  }
  a {
    display: flex;
    align-items: center;
  }
  &:hover {
    background-color: #181818;
  }
`;

// First, add this styled component near the other styled components
// Add this import at the top

// Update the TooltipContainer styling
const TooltipContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 8px;

  .tooltip-text {
    visibility: hidden;
    width: 280px;
    background-color: white;
    color: #333;
    text-align: left;
    padding: 12px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    position: absolute;
    z-index: 100;
    font-size: 14px;
    opacity: 0;
    transition: all 0.2s ease;
    font-weight: normal;
    left: 80%;
    transform: translateX(-50%);
    top: -120%;
    width: 250px;

    ${respondTo.sm`
      left: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
      width: 280px;
    `}
  }

  .icon:hover + .tooltip-text {
    visibility: visible;
    opacity: 1;
  }

  .icon {
    color: #707070;
    cursor: help;
  }
`;

const Punti = ({ text, num }) => {
  const appName = useSelector(state => state.app.config.code);
  const { actionsPerCategory } = useSelector(state => state.user.user || {});

  const tooltips = {
    'Punti Bollettati':
      'Il totale del tuo fatturato accumulato fino ad oggi, convertito in crediti',
    'Punti Pagati':
      "Corrispondono alle fatture già saldate. Questi punti potranno essere utilizzati solo una volta raggiunto l'obiettivo specificato nella pattuizione",
    'Punti Consumati': 'I punti già utilizzati per ordinare i premi',
    'Punti Spendibili':
      "Il saldo disponibile dei tuoi punti, calcolato come la differenza tra i 'Punti Pagati' e i 'Punti Consumati'",
  };

  const renderText = () => {
    if (appName === 'Ciicaiclub2025') {
      return (
        <TooltipContainer>
          <Text size={20} text_box>
            {text}
          </Text>
          <BsQuestionCircle size={16} className='icon' />
          <Text as='span' className='tooltip-text' size={12}>
            {tooltips[text]}
          </Text>
        </TooltipContainer>
      );
    }
    return (
      <Text size={20} text_box>
        {text}
      </Text>
    );
  };

  return text === 'Punti Bollettati' && appName === 'Ciicaiclub2025' ? (
    <Details val={num}>
      <summary>
        {renderText()}
        <Text size={20} align='right' bold text_box>
          {numberWithDots(num)}
        </Text>
      </summary>

      {actionsPerCategory &&
        Object.keys(actionsPerCategory)?.map(item => (
          <Flex key={item} justify='space-between' align='center' wrap='nowrap' className='mb-10'>
            <Text size={20} text_box>
              {item}
            </Text>
            <Text size={20} text_box>
              {actionsPerCategory[item].total}
            </Text>
          </Flex>
        ))}
    </Details>
  ) : (
    <Flex justify='space-between' align='center' wrap='nowrap'>
      <Col width={50}>{renderText()}</Col>
      <Col width={50}>
        <Text size={20} align='right' bold text_box>
          {numberWithDots(num)}
        </Text>
      </Col>
    </Flex>
  );
};
const Coin = ({ text, num, category, date }) => (
  <>
    <Flex align='center' wrap='nowrap'>
      <Col width={80}>
        <Flex gap={30}>
          <Col width={50} padding={5}>
            <Flex wrap='nowrap' gap={5}>
              <CustomText bold capit text_box>
                Data:
              </CustomText>
              <Text size={18} text_box style={{ whiteSpace: 'nowrap' }}>
                {moment(date).format('DD/MM/YYYY HH:MM')}
              </Text>
            </Flex>
          </Col>
          <Col width={50} padding={5}>
            <Text size={18} text_box>
              {category}
            </Text>
          </Col>
        </Flex>
      </Col>
      <CustomColPoint width={20}>
        <Text size={18} upper type='text' text_box align='right'>
          {numberWithDots(num)}
        </Text>
      </CustomColPoint>
    </Flex>
  </>
);

const Header = styled.div`
  background: ${({ theme }) => `color-mix(in srgb, ${theme.bg_header}, black 20%)`};
  color: white;
  padding: 15px 20px;
  margin-top: -10px;
  margin-bottom: 20px;
`;

const Section = styled.div`
  background: white;
  margin-bottom: 1px;
`;
const CustomPattuizione = styled.div`
  .title {
    color: ${({ theme }) => theme.bg_bottoni};
    padding: 15px 10px 0 10px;
    svg {
      color: ${({ theme }) => theme.bg_bottoni};

      width: 30px;
      height: 30px;
    }
  }

  .text {
    padding: 10px 35px;
    a {
      color: ${({ theme }) => theme.default_color};
    }
  }
`;
const SectionHeader = styled.div`
  padding: 15px 10px 0 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  .title {
    color: ${({ theme }) => theme.bg_bottoni};
  }
  svg {
    color: ${({ theme }) => theme.bg_bottoni};
    transition: transform 0.3s ease;
    width: 30px;
    height: 30px;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  }
`;

const SectionContent = styled.div`
  padding: ${({ isOpen }) => (isOpen ? '10px' : '0')};
  max-height: ${({ isOpen }) => (isOpen ? '2000px' : '0')};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
`;

// Update the HomePoints component
const HomePoints = () => {
  const [openSections, setOpenSections] = useState({
    details: true,
    history: true,
  });

  // const ExcelFile = ReactExport.ExcelFile;
  // const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  // const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const {
    userinfo: { score, score_spent, score_gained, score_pending } = {},
    actions,
    base_threshold_enabled,
    base_threshold_description,
    base_threshold_percent,
    targets,
    actionsPerCategory,
  } = useSelector(state => state.user.user || {});
  const { individual_agreement_accepted_date, individual_agreement_file } = useSelector(
    state => state.user.user?.userinfo || {},
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  return (
    <Wrapper>
      <Header>
        <Flex justify='space-between' align='center' className='mb-10'>
          <Text color='white' size={14}>
            Benvenuto
          </Text>
          <Text color='white' size={14}>
            Saldo punti
          </Text>
        </Flex>
        <Flex justify='space-between' align='center'>
          <Text color='white' size={24} bold>
            TISA
          </Text>
          <Text color='white' size={24} bold>
            {numberWithDots(score)}
          </Text>
        </Flex>
      </Header>
      <CustomPattuizione className='mb-30 mt-30'>
        <Flex align='center' gap={10} className='mb-10'>
          <Text bold size={25} className='title'>
            PATTUIZIONE
          </Text>
        </Flex>

        <Text className='text'>
          Il tuo accordo firmato il{' '}
          {moment(new Date(individual_agreement_accepted_date)).format('DD/MM/YYYY')}:{' '}
          <a href={individual_agreement_file} download target='_blank' rel='noopener noreferrer'>
            DOWNLOAD
          </a>
        </Text>
      </CustomPattuizione>
      <Section>
        <SectionHeader
          isOpen={openSections.details}
          onClick={() => setOpenSections(prev => ({ ...prev, details: !prev.details }))}
        >
          <Text bold className='title' size={25}>
            DETTAGLIO PUNTI
          </Text>
          <IoIosArrowDown size={20} />
        </SectionHeader>
        <SectionContent isOpen={openSections.details}>
          <CustomBox>
            <Punti
              text='Punti Bollettati'
              num={score_gained}
              actionsPerCategory={actionsPerCategory}
            />
            <Punti text='Punti Pagati' num={score_spent} />
            <Punti text='Punti Consumati' num={score} />
            <Punti text='Punti Spendibili' num={score_pending} />
          </CustomBox>
        </SectionContent>
      </Section>

      {actions?.length > 0 && (
        <Section>
          <SectionHeader
            isOpen={openSections.history}
            onClick={() => setOpenSections(prev => ({ ...prev, history: !prev.history }))}
          >
            <Text bold size={25} className='title'>
              STORICO
            </Text>
            <IoIosArrowDown size={20} />
          </SectionHeader>
          <SectionContent isOpen={openSections.history}>
            {/* Existing history content */}
            <Box style={{ height: '100%' }}>
              <CustomFlexHeader align='center' wrap='nowrap'>
                <Col width={80} padding={0}>
                  <Flex>
                    <Col width={50}>
                      <Text size={18} bold primary align='left' style={{ paddingLeft: '8px' }}>
                        Data
                      </Text>
                    </Col>
                    <Col width={50} style={{ paddingLeft: '0' }}>
                      <Text size={18} bold primary align='left'>
                        Categorie
                      </Text>
                    </Col>
                  </Flex>
                </Col>
                <Col width={20} padding={0}>
                  <Text size={18} bold primary align='right' style={{ paddingRight: '20px' }}>
                    Punti
                  </Text>
                </Col>
              </CustomFlexHeader>
              <ReactShadowScroll
                style={{ width: '100%', maxHeight: '370px' }}
                isShadow={false}
                scrollColor={'#cccccc'}
                scrollColorHover='gray'
                styleSubcontainer={{ overflowX: 'hidden' }}
              >
                {actions?.map(({ id, details, points, category, created_at }) => (
                  <Coin key={id} num={points} text={details} category={details} date={created_at} />
                ))}
              </ReactShadowScroll>
            </Box>
          </SectionContent>
        </Section>
      )}
    </Wrapper>
  );
};
export default HomePoints;
